import { useScrollToTop } from 'hooks/useScrollToTop'
import { lazyComponent } from 'modules/helpers/lazyComponent'
import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
// import LazyHydrate from 'react-lazy-hydration'
const Home = lazyComponent(() => import('./pages/Home'))
const AboutUs = lazyComponent(() => import('pages/AboutUs'))
const Contact = lazyComponent(() => import('pages/Contact'))
const Impressum = lazyComponent(() => import('pages/Impressum'))
const LegalInfo = lazyComponent(() => import('pages/LegalInfo'))
const PrivacyPolicy = lazyComponent(() => import('pages/PrivacyPolicy'))
const TermsConditions = lazyComponent(() => import('pages/TermsConditions'))
const Fx = lazyComponent(() => import('./pages/Fx'))
const Pay = lazyComponent(() => import('./pages/Pay'))
const FxGlossary = lazyComponent(() => import('pages/FxGlossary'))
const FxAffiliate = lazyComponent(() => import('pages/FxAffiliate'))
const PayAffiliate = lazyComponent(() => import('pages/PayAffiliate'))
const Faq = lazyComponent(() => import('pages/Faq'))
const FaqQuestion = lazyComponent(() => import('pages/FaqQuestion'))
const NotFound = lazyComponent(() => import('./pages/NotFound'))
const Error50x = lazyComponent(() => import('./pages/Error50x'))

export const PATH = {
	HOME: '/',
	ABOUT_US: '/about-us',
	CONTACT: '/contact',
	LEGAL_INFO: '/legal-info',
	IMPRESSUM: '/impressum',
	TERMS_CONDITIONS: '/terms-conditions',
	PRIVACY_POLICY: '/privacy-policy',
	FX: '/exchange',
	PAY: '/payment-gate',
	FAQ: '/faq',
	ERROR_50X: '/50x',
}
export const PATH_PAY = {
	AFFILIATE: `${PATH.PAY}/affiliate`,
}
export const PATH_FX = {
	HASH_HOW_IT_WORKS: `how-it-works`,
	GLOSSARY: `${PATH.FX}/glossary`,
	AFFILIATE: `${PATH.FX}/affiliate`,
}
export const CONTACT_PARAMS = {
	PAY: 'pay',
	FX: 'fx',
}

interface IStandardRoute {
	path: string
	exact?: boolean
}
const StandardRoute: FC<IStandardRoute> = ({ children, path, exact = false }) => <Route path={path} exact={exact} render={() => children} />

// TODO (low): add loader - rotating logo or text loader
// TODO (low): animations 'motion'|'https://animejs.com/'

export const Routes: FC = () => {
	useScrollToTop()

	return (
		<Switch>
			<StandardRoute path={PATH.ABOUT_US}>
				<AboutUs />
			</StandardRoute>
			<StandardRoute path={PATH.CONTACT + '/:product?'}>
				<Contact />
			</StandardRoute>
			<StandardRoute path={PATH.IMPRESSUM}>
				{/*<LazyHydrate ssrOnly>*/}
				<Impressum />
				{/*</LazyHydrate>*/}
			</StandardRoute>
			<StandardRoute path={PATH.LEGAL_INFO}>
				{/*<LazyHydrate ssrOnly>*/}
				<LegalInfo />
				{/*</LazyHydrate>*/}
			</StandardRoute>
			<StandardRoute path={PATH.PRIVACY_POLICY}>
				{/*<LazyHydrate ssrOnly>*/}
				<PrivacyPolicy />
				{/*</LazyHydrate>*/}
			</StandardRoute>
			<StandardRoute path={PATH.TERMS_CONDITIONS}>
				{/*<LazyHydrate ssrOnly>*/}
				<TermsConditions />
				{/*</LazyHydrate>*/}
			</StandardRoute>
			<StandardRoute path={PATH.FAQ}>
				<Switch>
					<StandardRoute path={PATH.FAQ + '/:categoryId?'} exact>
						<Faq />
					</StandardRoute>
					<StandardRoute path={PATH.FAQ + '/:categoryId/:questionId'}>
						<FaqQuestion />
					</StandardRoute>
				</Switch>
			</StandardRoute>
			<StandardRoute path={PATH.FX}>
				<Switch>
					<StandardRoute path={PATH.FX} exact>
						<Fx />
					</StandardRoute>
					<StandardRoute path={PATH_FX.GLOSSARY}>
						<FxGlossary />
					</StandardRoute>
					<StandardRoute path={PATH_FX.AFFILIATE}>
						<FxAffiliate />
					</StandardRoute>
				</Switch>
			</StandardRoute>
			<StandardRoute path={PATH.PAY}>
				<>
					<Switch>
						<StandardRoute path={PATH.PAY} exact>
							<Pay />
						</StandardRoute>
						<StandardRoute path={PATH_PAY.AFFILIATE}>
							<PayAffiliate />
						</StandardRoute>
					</Switch>
				</>
			</StandardRoute>
			<StandardRoute path={PATH.ERROR_50X}>
				{/*<LazyHydrate ssrOnly>*/}
				<Error50x />
				{/*</LazyHydrate>*/}
			</StandardRoute>
			<StandardRoute path={PATH.HOME} exact>
				{/*<LazyHydrate ssrOnly>*/}
				<Home />
				{/*</LazyHydrate>*/}
			</StandardRoute>
			<Route path={'*'} render={() => <NotFound />} />
			{/*<Redirect to={'/404'} />*/}
		</Switch>
	)
}
