import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const useScrollToTop = (): void => {
	const { pathname, hash } = useLocation()

	useEffect(() => {
		if (!hash) {
			window.scrollTo(0, 0)
		}
	}, [hash, pathname])
}
