import { createGlobalStyle } from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'
import { IThemeStyles } from 'styles/themeStyles'

const GlobalStyles = createGlobalStyle<{ theme: IThemeStyles }>` 
	body {
		margin: 0;
		padding: 0;
		font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
		box-sizing: border-box;
		color: #000;
    ${FontSizeResponsive({ size: 'medium' })}
		line-height: ${({ theme }) => theme.lineHeight.big};
		font-weight: ${({ theme }) => theme.weight.normal};
	}
 
	*, *:before, *:after {
		box-sizing: border-box;
	}
	
	a, .ant-menu-item a {
    cursor: pointer;
    
    &,:active, :hover{
      color: inherit;
      text-decoration: none;
    }
	}
  strong{
		font-weight: ${({ theme }) => theme.weight.extraBold};
  }
  h1, h2, h3, h4, h5, h6{
    font-size: inherit;
    margin: 0;
    padding: 0;
		font-weight: ${({ theme }) => theme.weight.extraBold};
    color: inherit;
  }
  ol {
    padding: 0;
    margin: 0;
  }
  ul {
    list-style: disc;
  }
  svg {
    max-width: 100%;
  }
  .no-wrap{
    white-space: nowrap;
  }
  // <Statistic /> component
  .ant-statistic-content,
  .ant-statistic {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: inherit;
    display: inline;
  }
`

export { GlobalStyles }
