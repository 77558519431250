import { ErrorBoundary, LEVEL_WARN, Provider } from '@rollbar/react'
import { useRollbar } from 'hooks/useRollbar'
import { lazyComponent } from 'modules/helpers/lazyComponent'
import { FC } from 'react'
import { Routes } from 'Routes'

const PageFooter = lazyComponent(() => import('layout/PageFooter'))
const PageHeader = lazyComponent(() => import('layout/PageHeader'))
// import LazyHydrate from 'react-lazy-hydration'

const App: FC = () => {
	const { rollbarConfig, rollbar } = useRollbar()

	return (
		<Provider instance={rollbar} config={rollbarConfig}>
			<ErrorBoundary level={LEVEL_WARN}>
				<PageHeader />
				<Routes />
				{/*<LazyHydrate ssrOnly>*/}
				<PageFooter />
				{/*</LazyHydrate>*/}
			</ErrorBoundary>
		</Provider>
	)
}

export default App
