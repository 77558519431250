import { css, FlattenInterpolation, FlattenSimpleInterpolation } from 'styled-components'
import { IThemeScreen } from 'styles/themeStyles'

interface IProps {
	screen: keyof IThemeScreen
}

export const MobileView = (styles: FlattenSimpleInterpolation | FlattenInterpolation<any>, params: IProps = { screen: 'sm' }): any => css`
	@media (max-width: ${({ theme }) => theme.screen[params.screen]}) {
		${styles}
	}
`
export const DesktopView = (styles: FlattenSimpleInterpolation | FlattenInterpolation<any>, params: IProps = { screen: 'sm' }): any => css`
	@media (min-width: ${({ theme }) => theme.screen[params.screen]}) {
		${styles}
	}
`
