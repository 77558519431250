export const ENV = {
	ENV: process.env.REACT_APP_ENV, // local, development, sandbox, production
	IS_PRODUCTION: process.env.REACT_APP_ENV === 'production',
	GTM_ID: process.env.REACT_APP_GTM_ID,
	ROLLBAR_TOKEN: process.env.REACT_APP_ROLLBAR_TOKEN,
	MAIL_BROKER_TOKEN: process.env.REACT_APP_MAIL_BROKER_TOKEN || '',
	MAIL_BROKER_URL: process.env.REACT_APP_MAIL_BROKER_URL || '', // http://localhost:3001
	BPAY_LOGIN_URL: process.env.REACT_APP_BPAY_LOGIN_URL || '',
	BPAY_API_DOC: process.env.REACT_APP_BPAY_API_DOC || '',
	BFX_URL: process.env.REACT_APP_BFX_URL || '',
	BFX_API_URL: process.env.REACT_APP_BFX_API_URL || '',
	MDS_API_URL: process.env.REACT_APP_MDS_API_URL || '',
	KYC_URL: process.env.REACT_APP_KYC_URL || '',
}
