import { css } from 'styled-components'
import { MobileView } from 'styles/mixins/MobileView'
import { IThemeScreen, IThemeSizes } from 'styles/themeStyles'

const sizes: (keyof IThemeSizes)[] = ['big', 'header', 'subheader', 'normal', 'medium', 'small', 'mini']
const getSmallerSize = (size: keyof IThemeSizes): keyof IThemeSizes => {
	const smallestSizeIndex = sizes.indexOf(size) + 1

	return sizes[smallestSizeIndex] || size
}

interface IProps {
	size: keyof IThemeSizes
	mobileSize?: keyof IThemeSizes
	mobileScreen?: keyof IThemeScreen
}
export const FontSizeResponsive = ({ size, mobileSize, mobileScreen = 'sm' }: IProps): any => css`
	font-size: ${({ theme }) => theme.size[size]};

	${MobileView(
		css`
			font-size: ${({ theme }) => theme.size[mobileSize || getSmallerSize(size)]};
		`,
		{ screen: mobileScreen },
	)}
`
interface IPropsStatic {
	size: string
	mobileSize: string
	mobileScreen?: keyof IThemeScreen
}
export const FontSizeResponsiveStatic = ({ size, mobileSize, mobileScreen = 'sm' }: IPropsStatic): any => css`
	font-size: ${size};

	${MobileView(
		css`
			font-size: ${mobileSize};
		`,
		{ screen: mobileScreen },
	)}
`
