// import reportWebVitals from './reportWebVitals'
import 'antd/dist/antd.min.css'
import React, { FC } from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { startGtm } from 'scripts/gtm'
import { ThemeProvider } from 'styled-components'
import 'styles/fonts.css'
import { GlobalStyles } from 'styles/GlobalStyles'
import { themeStyles } from 'styles/themeStyles'
import App from './App'

startGtm()

const Application: FC = () => (
	<React.StrictMode>
		<ThemeProvider theme={themeStyles}>
			<GlobalStyles />
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ThemeProvider>
	</React.StrictMode>
)

const rootElement = document.getElementById('root')

if (rootElement && rootElement.hasChildNodes()) {
	hydrate(<Application />, rootElement)
} else {
	render(<Application />, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
