import { ENV } from 'config/env'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Rollbar from 'rollbar'
import { historyContext } from '@rollbar/react'

const rollbarConfig =
	ENV.ENV === 'local'
		? {}
		: {
				accessToken: ENV.ROLLBAR_TOKEN,
				environment: ENV.ENV,
				server: {
					root: location.origin,
				},
		  }
const rollbar = new Rollbar(rollbarConfig)

interface IReturn {
	rollbarConfig: object
	rollbar: any
}
export const useRollbar = (): IReturn => {
	const history = useHistory()

	useEffect(() => {
		history.listen(historyContext(rollbar))
	}, [history])

	return {
		rollbarConfig,
		rollbar,
	}
}
