import { getLuminance, lighten } from 'polished'
import style from './styles.json'

export interface IThemeSizes {
	big: string
	header: string
	subheader: string
	normal: string
	medium: string
	small: string
	mini: string
}
export interface IThemeColors {
	white: string
	black: string
	blueDark: string
	blue: string
	green: string
	grayDarker: string
	grayDark: string
	gray: string
	grayLight: string
	grayWhite: string
	orange: string
	pink: string
}
export interface IThemeLineHeight {
	big: string
	medium: string
	small: string
}
export interface IThemeScreen {
	xs: string
	sm: string
	md: string
	lg: string
	xl: string
	hq: string
}
export interface IThemeWeight {
	normal: string
	bold: number
	extraBold: number
}

export interface IThemeStyles {
	color: IThemeColors
	size: IThemeSizes
	weight: IThemeWeight
	lineHeight: IThemeLineHeight
	screen: IThemeScreen
	shadowLightenColor: (color: string) => string
}

export const themeStyles: IThemeStyles = {
	...style,
	// when color is <gray, shadow have to be darker than usual, to doesnt be white
	shadowLightenColor: (color) => {
		const index = getLuminance(color) >= getLuminance(themeStyles.color.gray) ? 0.2 : 0.5
		return lighten(index, color)
	},
}
